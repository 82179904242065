.window-candidate-v2 {

  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  display: grid;

  grid-template-columns: auto 310px;
  grid-template-rows: auto;
  grid-template-areas: "body nav";

  //@include md-only {
  //  grid-template-columns: auto;
  //  grid-template-rows: auto;
  //  grid-template-areas: "nav" "body";
  //}

  @include sm-only {
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas: "nav" "body";
  }

  &__nav {
    grid-area: nav;
    background-color: darken($colors-V2-layout-grey, 5%);
    overflow-x: hidden;
    position: relative;
    padding: 21px;
  }

  &__body {
    grid-area: body;
    background-color: white;
    overflow-x: hidden;
    position: relative;

    transition: all 0.3s;

    &--messages {
      background-color: #3f444e;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

}

.window__candidate__avatar {
  position: relative;
  width: 190px;
  height: 190px;
  border-radius: 105px;
  margin: auto;
  overflow: hidden;

  background-color: $colors-V2-dominant1;

  color: white;
  font-size: 5rem;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  & img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.window__candidate__white-label {
  padding: 7px 20px;
  width: 95%;
  margin: 7px auto;
  background-color: white;
  height: 48px;
  border-radius: 24px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  font-family: 'geomanistbold', 'Lato', sans-serif;
  color: $blackish6;
  font-size: 0.9rem;
}

.window-candidate-v2__loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.window__candidate__name {
  font-family: 'geomanistbold', 'Lato', sans-serif;
  color: $blackish6;
  font-size: 1.3rem;
  border-radius: 15px;
}

.input-light {
  border-radius: 30px !important;
}

.window-candidate-v2__navbar {

  position: relative;

  //position: fixed;
  margin: 20px 30px;

  background-color: transparent;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  &__item {
    background: none;
    border: none;
    font-family: 'geomanistbold', 'Lato', sans-serif;
    color: #aaa;
    font-size: 0.95rem;
    margin: 0 5px;
    transition: all 0.3s;
    &:hover {
      color: #666;
    }
    &--selected {
      color: $blackish6;
    }
  }
}

.window-candidate-v2__documents {

  width: 95% ;
  margin: auto;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;

  &__document {
    background-color: white;
    position: relative;
    width: 100%;
    padding: 15px 25px;
    box-shadow: 0 2px 5px 0 rgba(#000, 0.3);
    border-radius: 15px;
    margin: 10px auto;
    opacity: 0;
    animation: fade-in-to-up 0.25s forwards;

    &:nth-child(1) {
      animation-delay: 0s;
    }
    &:nth-child(2) {
      animation-delay: 0.1s;
    }
    &:nth-child(3) {
      animation-delay: 0.2s;
    }
    &:nth-child(4) {
      animation-delay: 0.3s;
    }
    &:nth-child(5) {
      animation-delay: 0.4s;
    }
    &:nth-child(6) {
      animation-delay: 0.5s;
    }
    &:nth-child(7) {
      animation-delay: 0.6s;
    }
    &:nth-child(8) {
      animation-delay: 0.7s;
    }
  }
}

@keyframes fade-in-to-up {
  0% { opacity: 0; top: 30px; }
  30% { opacity: 0; top: 30px; }
  100% { opacity: 1; top: 0; }
}

.profile--highlighted {
  background-color: $yellow !important;
  & * { background-color: $yellow !important; }
}

.hollow {
  opacity: 0.3;
}

.window__candidate__mark {
  width: 70px;
  height: 40px;
  border-radius: 25px;
  background-color: $yellow;
  color: white;
  font-family: 'geomanistbold', 'Lato', sans-serif;
  border-color: $yellow;
}