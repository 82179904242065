
.color-pool {
  background: grey;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin: 5px;
  border: 2px solid white;

  transition: all 0.2s;

  &--green {
    background-color: #4aeed8;
  }
  &--blue {
    background-color: $colors-V2-dominant1;
  }
  &--yellow {
    background-color: $yellow;
  }
  &--orange {
    background-color: #ee5d4a;
  }
  &--pink {
    background-color: #ee4a7e;
  }

  &--selected {
    border: 2px solid black;
  }
}