
.profile-edit {

  &__title {
    font-size: 1.5rem;
    color: $blackish8;
    font-family: 'geomanistbold', 'Lato', sans-serif;
    margin-bottom: 8px;
    position: relative;
    //left: 30px;
    @include sm-only {
      left: 0px;
      font-size: 1.5rem;
    }
  }

  &__label {

    font-size: 0.9rem;

    &__image {
      width: 28px;
      height: 28px;
      margin: 5px;
      color: $blackish8;
    }
  }

  &__input {
    border: none;
    max-width: 98%;
    border-bottom: 1px solid black;
    background-color: #f9f9f9;
    font-size: 1.2rem;
    &--full-width {
      width: 98%;
      text-align: left;
    }
  }

  &__input3 {
    box-sizing: border-box !important;
    border: none !important;
    text-align: left !important;
    width: 98% !important;
    border-radius: 28px !important;
    padding: 8px 20px !important;
    height: 28px !important;
    @include sm-only {
      height: auto;
    }
    max-width: 98%;
    background-color: #f9f9f9;
  }

  &__textarea {
    font-size: 1rem;
    max-width: 98%;
    border: none;
    height: 120px;
    border-radius: 15px;
    background-color: #f9f9f9;
    padding: 10px;
    width: 98%;
    text-align: left;
  }

}

.profile-edit {

  &__pictures-container {

    background-color: lightblue;
    border-radius: 20px;



    margin: auto;

    width: 98%;
    overflow: hidden;

    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    padding: 30px;

    @include sm-only {
      padding: 15px;
    }



    &__video {
      margin: 15px;
    }

    &__picture {
      width: 320px;
      height: 240px;
      object-fit: cover;
      margin: 15px;

      &--ban {
        width: 420px;
      }
      &--square {
        width: 260px;
      }
      &--portrait {
        width: 200px;
      }
      &--small {
        width: 200px;
        height: 120px;
      }
    }

    &__columns {
      display: grid;
      grid-template-columns: 25% 25% 25% 25%;
      grid-template-rows: auto;
      & article {
        min-width: 200px;
      }
    }
  }
}

.profile-edit__form {

  max-width: 98%;
  overflow: hidden;
}

.profile-edit__pictures-container {

    &__columns {
      width: 100%;
      display: grid;
      grid-template-columns: 25% 75%;
      grid-template-rows: auto;

      grid-template-areas: "description content";

      &__description {
        margin-top: 5px;
        grid-area: description;
      }

      &__content {
        grid-area: content;

        &__header {
          width: 100%;
          overflow: hidden;

          display: flex;
          flex-flow: row wrap;

          justify-content: center;
          align-items: flex-start;
          align-content: flex-start;
        }

        &__gallery {
          width: 100%;

          display: flex;
          flex-flow: row nowrap;
          justify-content: center;
          align-items: flex-start;
          align-content: flex-start;

          &__column {
            display: flex;
            flex-flow: column nowrap;
            justify-content: flex-start;
            align-content: flex-start;
            align-items: center;

            min-width: 200px;

          }
        }
      }

      @include sm-only {
        display: none;
      }
    }

  //& article {
  //  min-width: 200px;
  //}

}


.profile-edit__pictures-container__columns__description__div {
  background-color: white;
  width: 100%;
  min-height: 500px;

}


$el2-single-width: 200px;
$el2-single-height: 120px;
$el2-margin: 5px;

$el2-double-width: 2 * $el2-single-width + 2 * $el2-margin;
$el2-double-height: 2 * $el2-single-height + 2 * $el2-margin;


.profile-edit__pictures-container__el {

  &--1--1 {
    position: relative;
    background-color: white;
    width: $el2-single-width;
    height: $el2-single-height;
    margin: $el2-margin $el2-margin;
  }

  &--2--2 {
    position: relative;
    background-color: white;
    width: $el2-double-width;
    height: $el2-double-height;
    margin: $el2-margin $el2-margin;
  }

  &--1--2 {
    position: relative;
    background-color: white;
    width: $el2-single-width;
    height: $el2-double-height;
    margin: $el2-margin $el2-margin;
  }

  &--1--free {
    position: relative;
    background-color: white;
    width: $el2-single-width;
    //min-height: $el2-single-height;
    margin: $el2-margin $el2-margin;
  }

  &--flex {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }

  &__close {
    position: absolute;
    top:0;
    right: 0;
  }

  &__image {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__free-text {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    padding: 15px 20px;

    & p {
      text-align: center;
    }
  }

}

.profile-edit--banV2 {
  width: 95%;
  max-width: 600px;
  height: 250px;
  object-fit: cover;
}

.profile-edit__value {

  padding: 8px;
  background-color: white;
  width: 100%;
  min-height: 30px;
  border-radius: 15px;
  margin: 8px 0;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  &__body {
    flex-grow: 1;
  }

  &__buttons {
    flex-grow: 0;
    flex-shrink: 0;
  }
}

.profile-edit-btn {

  border: none;
  background-color: #bbb;

  font-family: 'geomanistbold', 'Lato', sans-serif;
  color: white;

  padding: 7px 10px;
  border-radius: 20px;

  margin: 5px;

  font-size: 0.9rem;

  transition: background-color 0.2s;

  &:disabled {
    opacity: 0.3 !important;
  }

  &:hover {
    background-color: $yellow;
  }

}

.white-board-profile {

  max-width: 98%;
  margin: 10px auto;
  border-radius: 15px;
  background-color: white;
  padding: 10px;
}