
$editor-V2-min-width: 900px;
$editor-V2-max-width: 1100px;

.editor-v2__actions {

  width: 95%;
  min-width: $editor-V2-min-width;
  max-width: $editor-V2-max-width;
  margin: auto;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;


  &__btn {
    flex: 1;
    margin: 5px;
    min-height: 44px;
    border-radius: 22px;
    border: none;
    background: $colors-V2-blue2;
    font-weight: bolder;
    color: white;
    transition: all 0.2s;
    &:hover {
      background: lighten($colors-V2-blue2, 15%);
    }
    &:disabled {
      opacity: 0.4;
    }
  }
}


.editor-v2__section {

  background-color: $colors-V2-layout-grey;

  width: 95%;
  min-width: $editor-V2-min-width;
  max-width: $editor-V2-max-width;

  min-height: 100px;

  border-radius: 15px;

  padding: 30px 30px 60px 30px;

  margin: auto;

  &__title {
    text-align: center;
    font-family: 'geomanistblack', 'Lato', sans-serif;
    color: $blackish6;
    font-size: 2rem;
    margin-bottom: 30px;
  }

}

.editor-v2__section__question {

  position: relative;

  width: 100%;
  max-width: 900px;
  height: 70px;

  &--extended {
    height: auto;
  }
  background-color: white;
  border-radius: 45px;

  margin: 15px auto;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  overflow: hidden;

  &__sticker {

    width: 60px;
    height: 100%;

    //background-color: $colors-V2-blue2;
    background-color: white;

    //color: white;
    color: $colors-V2-blue2;

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    font-family: 'geomanistbold', 'Lato', sans-serif;

    font-size: 1.5rem;

      &--blue {
        //background-color: $colors-V2-blue2;
      }

      &--orange {
        //background-color: darkorange;
        color: darkorange;
      }

  }

  &__content {
    flex-grow: 1;
    min-height: 100%;

    &__header {
      height: 70px;
      padding: 10px 20px 10px 10px;

      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;

    }

    &__extended {

      max-width: 95%;
      width: 100%;

      padding-bottom: 35px;

      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: stretch;
    }

  }


}


.editor-v2__section__question__textarea {

  width: 90%;
  margin: auto;
  min-height: 50px;
  text-align: left;
}

.editor-v2__section__question__answer {

  width: 100%;
  padding: 5px;
  //background-color: coral;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;

  transition: all 0.3s;

  animation: fade-in 0.4s;

  &:hover {
    background-color: $colors-V2-layout-grey;
  }

  &__input {
    width: 400px;
    height: 40px;
    text-align: left;
  }

  &__logo {

  }

  &__choices {

  }
}

.small-margin-left {
  margin-left: 15px;
}

.btn-answer-editor {
  width: 40px;
  height: 40px;

  opacity: 1;
  filter: saturate(100%);

  transition: all 0.4s;

  &--disabled {
    transition: all 0.4s;
    opacity: 0.3;
    filter: saturate(10%);
  }

  &-btn {
    margin-left: 25px;
    transition: all 0.4s;
  }
}

.editor-v2__section__question--empty  {
  width: 100%;
  max-width: 900px;
  height: 70px;
  &--extended {
    height: auto;
  }
  background-color: white;
  border-radius: 45px;

  margin: 15px auto;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  overflow: hidden;

}

.entire-width {
  width: 100%;
}

.editor-v2__section__question__titles {
  width: 100%;
  text-align: center;
}