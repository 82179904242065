
.rating-component {

  &--white {

      transition: all 0.3s;

      width: 36px;
      height: 36px;

      border: none;
      border-bottom: 1px solid $dominant1;
      border-top: 1px solid $dominant1;
      border-right: 1px solid $dominant1;

      &:first-child {
        border-left: 1px solid $dominant1;
      }

      //&:last-child {
      //  border-right: 1px solid $dominant1;
      //}

      background-color: white;
      color: $dominant1;

  }

  //& button {
  //
  //  width: 36px;
  //  height: 36px;
  //
  //  border: none;
  //  border-bottom: 1px solid $dominant1;
  //  border-top: 1px solid $dominant1;
  //  border-right: 1px solid $dominant1;
  //
  //  &:first-child {
  //    border-left: 1px solid $dominant1;
  //  }
  //
  //  //&:last-child {
  //  //  border-right: 1px solid $dominant1;
  //  //}
  //
  //  background-color: white;
  //  color: $dominant1;
  //
  //}

}

.rating-component--selected {
  background-color: $dominant1;
  color: $dominant1text;
}