
.ambassadors--v2 {

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;


  &__ambassador {

    margin: 15px auto;
    width: 95%;
    max-width: 900px;

    height: 90px;
    border-radius: 45px;

    padding: 0 10px;

    background-color: white;

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    @include sm-only {
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      height: auto;
      padding: 20px;
    }

    animation: fade-in 0.3s;

    &__left {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      @include sm-only {
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
      }
    }

    &__right {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
      align-items: center;
      @include sm-only {
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
      }
    }

    &__element {
      margin: 0 30px;
      @include sm-only {
        margin: 10px auto;
      }
    }

    &__slug {
      width: 60px;
      border-radius: 6px;
      padding: 4px;
      background-color: #adadad;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 0.8rem;
    }

    &__avatar {
      width: 70px;
      height: 70px;
      border-radius: 35px;
      background-color: $colors-V2-blue2;
      overflow: hidden;
      color: white;
      font-size: 1.8rem;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
    }


    &__button {
      margin: 5px;
      font-family: 'geomanistblack', 'Lato', sans-serif;
      font-size: 1.1rem;
      color: white;
      background-color: #bbb;
      height: 40px;
      padding: 0 15px;
      border-radius: 20px;
      border: none;
      transition: all 0.2s;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      &:hover {
        background-color: $yellow;
      }
    }

  }
}