
.footer {

  @include sm-only {
  }

  & .container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    @include sm-only {
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: center;
    }
  }

  width: 100%;

  margin-top: 10px;
  margin-bottom: 8px;

  @include sm-only {
    margin-bottom: 60px;
  }

  &__sup {
    background-color: #ccc;
    padding: 42px 90px;

  }

  &__sub {

    padding: 42px 90px;

    &__list {

      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;

      @include sm-only {
        flex-flow: column nowrap;
        //align-items: flex-start;
        margin-bottom: 20px;
      }

      &__item, li {
        padding: 7px 20px;

        @include sm-only {
          padding: 15px 10px;
          text-align: center;
        }
      }
    }

    @include sm-only {
      padding: 42px 10px;
    }

  }

  & hr {
    width: 90%;
    max-width: 900px;
    margin: 32px auto 22px auto;
  }
}

.footer__sub__title {
  font-family: 'geomanistbold', 'Lato', sans-serif;
  margin-bottom: 6px;
  font-size: 1.1rem;
}