
.pools--v2 {

  width: 95%;
  max-width: 1300px;

  margin: auto;

  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
}

.pool--v2 {

  position: relative;

  width: 290px;
  //height: 400px;
  border-radius: 30px;
  background-color: white;

  margin: 20px 13px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  border: 2px solid white;

  transition: all 0.2s;

  animation: fade-in 0.4s;

  &--active-research {
    border: 2px solid $colors-V2-blue2;
  }

  &__avatar {

    width: 160px;
    height: 160px;

    border-radius: 50%;
    margin: 20px auto;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    font-family: 'geomanistblack', 'Lato', sans-serif;
    color: white;
    font-size: 4.5rem;

    background-color: #4aeed8;

    &--green {
      background-color: #4aeed8;
    }
    &--blue {
      background-color: $colors-V2-dominant1;
    }
    &--yellow {
      background-color: $yellow;
    }
    &--orange {
      background-color: #ee5d4a;
    }
    &--pink {
      background-color: #ee4a7e;
    }
  }

  &__title {
    font-family: 'geomanistblack', 'Lato', sans-serif;
    color: $blackish6;
    font-size: 1.38rem;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
  }

  &__date {
    margin: 15px auto;
  }

  &__lock {
    position: absolute;
    top: 15px;
    left: 15px;
    width: 20px;
    height: 20px;
  }

  &__buttons {

    width: 95%;

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    &__main {
      margin: 5px;
      font-family: 'geomanistblack', 'Lato', sans-serif;
      font-size: 1.1rem;
      color: white;
      background-color: #bbb;
      height: 40px;
      padding: 0 15px;
      border-radius: 20px;
      border: none;
      transition: all 0.2s;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      &:hover {
        background-color: $yellow;
      }
    }

    &__parameters {
      margin: 5px;
      //background-color: #bbb;
      background-color: white;
      opacity: 0.3;
      height: 40px;
      width: 40px;
      border-radius: 20px;
      border: none;
      transition: all 0.1s;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      &:hover {
        //background-color: $newDominant1;
        opacity: 1;
      }
      & img {
        width: 30px;
        height: 30px;
      }
    }

    &__pinned {
      margin: 5px;
      //background-color: #bbb;
      background-color: white;
      opacity: 0.3;
      height: 40px;
      width: 40px;
      border-radius: 20px;
      border: none;
      transition: all 0.1s;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      &:hover {
        //background-color: $newDominant1;
        opacity: 1;
      }
      &--active {
        opacity: 1;
      }
      & img {
        width: 23px;
        height: 23px;
      }
    }

  }



}